import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {User} from '../../core/models/user';

export interface AuthState {
	token: string;
	user: User;
	profilePath: string;
}

export function createInitialState(): AuthState {
	return {
		token: localStorage.getItem('token') || null,
		user: null,
		profilePath: 'admin/preview'
	};
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'auth', resettable: true})
export class AuthStore extends Store<AuthState> {

	constructor() {
		super(createInitialState());
	}

	updateUserProfile(user: AuthState['user']) {
		this.update(state => ({
			...state,
			user
		}));
	}

	updateProfilePath(path: AuthState['profilePath']) {
		this.update(state => ({
			...state,
			profilePath: path
		}));
	}

}

