import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {Membership} from "../../core/models/package";
import {User} from "../../core/models/user";
import {Event} from "../../core/models/event";
import {Company} from "../../core/models/company";
import {LOCALSTORAGE_USERMAVEN_DATA} from "../../../global";
import {PaymentState} from "../../core/models/payment";
import {AuthQuery} from "../../store/auth";
import {filter, take} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class UsermavenService {
    public customProperties = {};
    public properties = {};

    constructor(private authQuery: AuthQuery) {
        let usermavenDataFromStorage = {};
        const localData = localStorage.getItem(LOCALSTORAGE_USERMAVEN_DATA);
        if (!!localData) {
            try {
                usermavenDataFromStorage = JSON.parse(localData);
            } catch (e) {
            }
        }
        if (!!usermavenDataFromStorage) {
            this.properties = usermavenDataFromStorage;
            this.customProperties = usermavenDataFromStorage['custom'] || {};
        }

        this.authQuery.user$.pipe(
            filter(user => !!user),
            take(1)
        ).subscribe(user => {
            if (this.properties?.['id'] !== user.id) {
                this.properties = {};
                this.customProperties = {};
            }
        })
    }

    trackEvent(eventName: string, eventProperties: any) {
        if (environment.production && window.usermaven) {
            window.usermaven('track', eventName, eventProperties);
        }
    }

    public fullUserIdentification(user: User) {
        this.updateUserProperties(user);
        this.properties["custom"] = this.customProperties;
        this.updateUserMaven();
    }

    public updateEvent(event: Event) {
        if (event.company) {
            this.updateCompany(event.company);
        }
        if (event.payment?.state === PaymentState.PAID && event.payment?.membership !== Membership.TRIAL) {
            this.customProperties['plan'] = Membership[event.payment.membership]
            this.properties["custom"] = this.customProperties;
            this.updateUserMaven();
        }
    }

    public updateCompany(company: Company) {
        this.updateCompanyProperties(company);
        this.updateUserMaven();
    }


    private updateCompanyProperties(company: Company) {
        this.properties["company"] = {
            id: company.id,
            name: company.name,
            created_at: company.createdAt.toDate(),
            custom: {
                plan: Membership[company.payment.membership],
            }
        }
    }

    private updateUserProperties(user: User) {
        this.properties["id"] = user.id
        this.properties["first_name"] = user.firstName
        this.properties["last_name"] = user.lastName
        this.properties["email"] = user.email
        this.customProperties['email_language'] = user.emailLanguage
    }

    private updateUserMaven() {
        if (this.properties) {
            localStorage.setItem((LOCALSTORAGE_USERMAVEN_DATA), JSON.stringify(this.properties));

            if (environment.production && !!window.usermaven) {
                window.usermaven('id', (this.properties));
            }
        }
    }
}