import {Injectable} from "@angular/core";
import {User, UserRoles} from "../../core/models/user";
import {productFruits} from 'product-fruits';
import {environment} from "../../../environments/environment";
import {dateTimeToServer} from "../utils/moment";
@Injectable({
    providedIn: 'root'
})
export class ProductFruitsService {
    public userEmail = '';
    public signUpAt = "";

    public initProductFruits(user: User) {
        this.userEmail = user.email;
        this.signUpAt = dateTimeToServer(user.registeredAt);
        const userInfo = {
            username: this.userEmail,
            email: user.email,
            firstname: user.firstName,
            lastname: user.lastName,
            signUpAt:  this.signUpAt
        }

        productFruits.init(
            environment.productFruits.key,
            localStorage.getItem('lang') || 'en',
            userInfo,
            {disableLocationChangeDetection: false}
        );
    }

    public updateUserData(customFields: any, role?: UserRoles) {
        productFruits.safeExec(() => {
            const userInfo: any = {
                username: this.userEmail,
                signUpAt: this.signUpAt,
                props: customFields
            };

            if (role) {
                userInfo.role = getUserRoleString(role);
            }
            // @ts-ignore
            window.productFruits.identifyUser(userInfo);
        });
    }
}

function getUserRoleString(role: UserRoles): string {
    switch (role) {
        case UserRoles.OWNER:
            return 'Owner';
        case UserRoles.ADMIN:
            return 'Admin';
        case UserRoles.MODERATOR:
            return 'Moderator';
        case UserRoles.ATTENDEE:
            return 'Attendee';
        case UserRoles.VISITOR:
            return 'Visitor';
    }
}