import {Moment} from 'moment-timezone';
import {ID} from '@datorama/akita';
import {dateTimeFromServerInLocalTimezone} from '../../shared/utils/moment';
import {PaymentState} from './payment';

export interface LineItemFromServer {
	id: ID;
	product: string;
	quantity: number;
	price: number;
	discount: number;
	tax: number;
	charged_price: number;
	tax_in_percent: number;
}

export interface LineItem {
	id: ID;
	product: string;
	quantity: number;
	price: number;
	discount: number;
	tax: number;
	chargedPrice: number;
	taxInPercent: number;
}

export interface InvoiceFromServer {
	id: ID;
	number: string;
	price: number;
	currency: string;
	country: string;
	url: string;
	created_at: string;
	overdue_at: string;
	start_at?: string;
	end_at: string;
	paid_at?: string;
	note?: string;
	transaction_id?: string;
	subscription_id?: string;
	event_id?: ID;
	prorate: boolean;
	line_items: LineItemFromServer[];
	refund_for?: string;
	is_refund: boolean;
	state: string;
}

export interface Invoice {
	id: ID;
	number: string;
	price: number;
	currency: string;
	country: string;
	url: string;
	createdAt: Moment;
	overdueAt: Moment;
	startAt?: Moment;
	endAt: Moment;
	paidAt?: Moment;
	note?: string;
	transactionId?: string;
	subscriptionId?: string;
	eventId?: ID;
	prorate: boolean;
	lineItems: LineItem[]
	refundFor?: string;
	isRefund: boolean;
	state: PaymentState;
}

export function createInvoice(params: Partial<InvoiceFromServer>): Invoice {
	const retArr = {
		id: params.id,
		number: params.number,
		price: params.price,
		currency: params.currency,
		country: params.country,
		url: params.url,
		createdAt: dateTimeFromServerInLocalTimezone(params.created_at),
		overdueAt: dateTimeFromServerInLocalTimezone(params.overdue_at),
		startAt: dateTimeFromServerInLocalTimezone(params.start_at),
		endAt: dateTimeFromServerInLocalTimezone(params.end_at),
		note: params.note,
		transactionId: params.transaction_id,
		subscriptionId: params.subscription_id,
		eventId: params.event_id,
		prorate: params.prorate,
		lineItems: params.line_items.map((lineItem => createLineItem(lineItem))),
		refundFor: params.refund_for,
		isRefund: params.is_refund,
		state: params.state
	} as Invoice;
	if (params.paid_at) {
		retArr.paidAt = dateTimeFromServerInLocalTimezone(params.paid_at);
	}
	return retArr;
}

export function createInvoiceToServer(params: Partial<Invoice>) {
	const retArr = {
		id: params.id,
		number: params.number,
		prorate: params.prorate,
		currency: params.currency,
		country: params.country,
		url: params.url,
		created_at: params.createdAt.utc().format('YYYY-MM-DD HH:mm:ss'),
		overdue_at: params.overdueAt.format('YYYY-MM-DD'),
		start_at: params.startAt.format('YYYY-MM-DD'),
		end_at: params.endAt.format('YYYY-MM-DD'),
		note: params.note,
		transaction_id: params.transactionId,
		subscription_id: params.subscriptionId,
		event_id: params.eventId,
		line_items: params.lineItems.map(lineItem => createLineItemToServer(lineItem))
	} as InvoiceFromServer;
	if (!!params.refundFor) {
		retArr.refund_for = params.refundFor;
	}
	return retArr;
}

export function createLineItem(params: Partial<LineItemFromServer>): LineItem {
	return {
		id: params.id,
		product: params.product,
		quantity: params.quantity,
		price: params.price,
		discount: params.discount,
		tax: params.tax,
		chargedPrice: params.charged_price,
		taxInPercent: params.tax_in_percent
	} as LineItem;
}

export function createLineItemToServer(params: Partial<LineItem>): LineItemFromServer {
	return {
		id: params.id,
		product: params.product,
		quantity: params.quantity,
		price: params.price,
		discount: params.discount,
		tax: params.tax,
		charged_price: params.chargedPrice,
		tax_in_percent: params.taxInPercent
	} as LineItemFromServer;
}