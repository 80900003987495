import {ID} from '@datorama/akita';
import {Membership} from './package';
import {dateTimeFromServerInLocalTimezone} from '../../shared/utils/moment';
import {Moment} from 'moment-timezone';
import {Invoice} from "./invoice";

export enum PaymentState {
	NONE = 'none',
	ISSUED = 'issued',
	PAID = 'paid',
	OVERDUE = 'overdue',
	CANCELLED = 'cancelled',
	ENDED = 'ended',
	REFUNDED = 'refunded',
}

export enum PaymentOrigin {
    FASTSPRING = 'fastspring',
    PADDLE = 'paddle',
    INVOICE = 'invoice'
}

export interface PaymentFromServer {
    id: ID;
    package: number;
    active_until: string;
    active: boolean;
    subscription_id: string;
    customer_id: string;
    next_charge_price: number;
    note: string;
    origin: string;
    state: string;
    can_edit: boolean;
    event_id?: ID;
}

export interface Payment {
    id: ID;
    membership: Membership;
    activeUntil: Moment;
    active: boolean;
    subscriptionId: string;
    customerId: string;
    nextChargePrice: number;
    note: string;
    origin: PaymentOrigin;
    state: PaymentState;
    canEdit: boolean;
    invoices: Invoice[];
    eventId?: ID;
}

export function createPayment(params: Partial<PaymentFromServer>) {
	return {
        id: params.id,
        membership: params.package as Membership,
        activeUntil: dateTimeFromServerInLocalTimezone(params.active_until),
        active: params.active,
        subscriptionId: params.subscription_id,
        customerId: params.customer_id,
        nextChargePrice: params.next_charge_price,
        note: params.note,
        origin: params.origin,
        state: params.state,
        canEdit: params.can_edit,
        eventId: params.event_id,
        invoices: []
    } as Payment;
}

export function createCompanyPaymentToServer(params: Partial<Payment>) {
	return {
        id: params.id,
        package: params.membership,
        active_until: params.activeUntil.format('YYYY-MM-DD'),
        subscription_id: params.subscriptionId,
        customer_id: params.customerId,
        next_charge_price: params.nextChargePrice,
        note: params.note,
        origin: params.origin,
        event_id: params.eventId
    } as PaymentFromServer;
}

export function createEventPaymentToServer(params: Partial<Payment>) {
    return {
        id: params.id,
        package: params.membership,
        customer_id: params.customerId,
        note: params.note,
        origin: params.origin,
        event_id: params.eventId
    } as PaymentFromServer;
}
