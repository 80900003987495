import {Injectable} from '@angular/core';
import Gleap from "gleap";
import {environment} from "../../../environments/environment";
import {User} from "../../core/models/user";

@Injectable({
    providedIn: 'root'
})
export class GleapService {

    private isInitialised = false;

    constructor() {
    }

    init() {
        Gleap.initialize(environment.gleap.key);
        this.isInitialised = true;
    }

    identifyUser(user: User) {
        if (!this.isInitialised || Gleap.isUserIdentified()) {
            return;
        }
        Gleap.identify(user.id.toString(), {
            name: user.firstName + ' ' + user.lastName,
            email: user.email,
            phone: user.phone,
            createdAt: user.registeredAt?.toDate(),
            customData: {
                appsumo: user.appsumo
            }
        })
    }

    logoutUser() {
        if (this.isInitialised) {
            Gleap.clearIdentity();
        }
    }
}
