import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Company, CompanyPackageRestrictions} from '../../core/models/company';

export interface CompaniesState extends EntityState<Company>, ActiveState {
	ui: {
		defaultCompanyId?: number;
	},
	restrictions: CompanyPackageRestrictions;
}

const initialState = {
	ui: {
		defaultCompanyId: null
	},
	restrictions: null
};

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'companies', resettable: true})
export class CompaniesStore extends EntityStore<CompaniesState> {

	constructor() {
		super(initialState);
	}

	updateUI(ui: CompaniesState['ui']) {
		this.update(state => ({
			ui: {
				...state.ui,
				...ui
			}
		}));
	}

	updateRestrictions(restrictions: CompaniesState['restrictions']) {
		this.update(state => ({
			restrictions: {
				...state.restrictions,
				...restrictions
			}
		}));
	}

}

