import {Injectable} from '@angular/core';
import {BehaviorSubject, fromEvent, Observable} from 'rxjs';
import {map, startWith, withLatestFrom} from 'rxjs/operators';
import {LoadingBarService} from '@ngx-loading-bar/core';

@Injectable()
export class GlobalService {

	delayedProgress$ = this.loader.progress$.pipe(
		withLatestFrom(this.loader.progress$),
		map(v => v[1]),
	);

	isScrolled$;
	isMobile$ = new BehaviorSubject(false);
	width$: Observable<number>;
	height$: Observable<number>;

	constructor(
		private loader: LoadingBarService,
	) {
		this.width$ = fromEvent(window, 'resize').pipe(
			map(() => {
				return window.innerWidth;
			}),
			startWith(window.innerWidth),
		);

		this.height$ = fromEvent(window, 'resize').pipe(
			map(() => {
				return document.documentElement.clientHeight;
			}),
			startWith(document.documentElement.clientHeight),
		);

		this.isScrolled$ = fromEvent(window, 'scroll').pipe(map(() => window.scrollY > 100));

		fromEvent(window, 'resize').pipe(
			map(() => {
				return window.innerWidth;
			}),
			startWith(window.innerWidth),
		).subscribe(width => {
			this.isMobile$.next(width < 1023);
		});
	}

}
