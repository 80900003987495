import {Injectable} from '@angular/core';
import {Query, toBoolean} from '@datorama/akita';
import {AuthState, AuthStore} from './auth.store';
import {Event} from '../../core/models/event';

@Injectable({providedIn: 'root'})
export class AuthQuery extends Query<AuthState> {
	isLoggedIn$ = this.select(state => toBoolean(state.token));
	user$ = this.select(state => state.user);

	constructor(protected store: AuthStore) {
		super(store);
	}

	isLoggedIn() {
		return toBoolean(this.getValue()?.token);
	}

	isSuperAdmin() {
		return toBoolean(this.getValue()?.user?.id === 1);
	}

	updateLastEvents(events: Event[]) {
		this.store.updateUserProfile({
			...this.getValue().user,
			lastEvents: events.map(e => +e.id)
		});
	}
}
