import {Injectable, Renderer2} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from "rxjs";
import {fromPromise} from "rxjs/internal/observable/innerFrom";

export interface AppleIDSignInResponse {
	authorization: {
		state: string,
		code: string,
		id_token: string
	},
	user?: {
		email: string,
		name: {
			firstName: string,
			lastName: string
		}
	}
}

@Injectable({
	providedIn: 'root'
})
export class AppleIdAuthService {

	private script;
	private state;

	constructor() {
		this.state = window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16);
	}

	init(renderer2: Renderer2) {
		this.script = renderer2.createElement('script');

		renderer2.setAttribute(this.script, 'src', 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js');
		renderer2.setAttribute(this.script, 'type', 'text/javascript');

		renderer2.appendChild(document.body, this.script);
	}

	destroy(renderer2: Renderer2) {
		renderer2.removeChild(document.body, this.script);
	}

	signIn(clientId: string, redirectURI: string): Observable<AppleIDSignInResponse> {
		AppleID.auth.init({
			clientId,
			scope: 'email name',
			redirectURI,
			state: this.state,
			usePopup: true
		});
		return fromPromise(AppleID.auth.signIn()).pipe(
			map((appleIDSignInResponse: AppleIDSignInResponse) => {
				if (appleIDSignInResponse.authorization.state !== this.state) {
					throw new Error('State not equal');
				}
				return appleIDSignInResponse;
			})
		);
	}
}
