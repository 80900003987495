import {ID} from '@datorama/akita';
import {AnalyticsLevel, IntegrationLevel} from "./company";

export enum Membership {
	FREE = 0,
	TRIAL = 1,
	PREMIUM = 2,
    SOLO = 3,
	ENTERPRISE = 4,
	ENTERPRISE_WHITELABEL = 6,
	BUSINESS = 7,
	BUSINESS_PLUS = 9,
	LIFETIME = 10,
	APP_SUMO_LIFETIME = 11,
	APP_SUMO_2_LIFETIME = 12,
	APP_SUMO_3_LIFETIME = 13,
	APP_SUMO_4_LIFETIME = 14,
	APP_SUMO_5_LIFETIME = 15,
	APP_SUMO_6_LIFETIME = 16,
	APP_SUMO_7_LIFETIME = 17
}

export enum PackageType {
	FREE = 'free',
	PREMIUM = 'premium',
	BUSINESS = 'business',
	ENTERPRISE = 'enterprise',
}

export enum ProductName {
	PREMIUM = 'premium',
	SOLO = 'solo',
	BUSINESS = 'business',
	ENTERPRISE = 'enterprise',
	ATTENDEE = 'attendee',
	BRANDING = 'branding',
	CNAME = 'cname',
	GAMIFICATION = 'gamification',
}

export interface PackageFromServer {
	id: ID;
	fast_spring_id: PackageType;
	price: number;
	display: string;
	currency: string;
	variables?: PackageFromServer[];
	alternatives?: PackageFromServer[];
}

export interface Package {
	id: ID;
	fastspringId: PackageType;
	price: number;
	display: string;
	currency: string;
	variables?: Package[];
	alternatives?: Package[];
}

export interface PackageRestrictionsFromServer {
	live_features: boolean,
	ads_free: boolean,
	feed: boolean,
	networking: boolean,
	files: boolean,
	access_restrictions: boolean,
	partners: boolean,
	analytics: AnalyticsLevel,
	custom_menu: boolean,
	tracks: boolean,
	integrations: IntegrationLevel,
	hide_website: boolean,
	hide_reviews: boolean,
	api_access: boolean,
	data_access_in_months: number,
	social_wall: boolean,
	branding: boolean,
	cname: boolean,
	gamification: boolean
}

export interface PackageRestrictions {
	liveFeatures: boolean,
	adsFree: boolean,
	feed: boolean,
	networking: boolean,
	files: boolean,
	accessRestrictions: boolean,
	partners: boolean,
	analytics: AnalyticsLevel,
	customMenu: boolean,
	tracks: boolean,
	integrations: IntegrationLevel,
	hideWebsite: boolean,
	hideReviews: boolean,
	apiAccess: boolean,
	dataAccessInMonths: number,
	socialWall: boolean,
	branding: boolean,
	cname: boolean,
	gamification: boolean
}

export function createPackage(params: Partial<PackageFromServer>): Package {
	return {
		id: params.id,
		price: params.price,
		display: params.display,
		currency: params.currency,
		fastspringId: params.fast_spring_id,
		variables: params.variables?.map(v => createPackage(v)),
		alternatives: params.alternatives?.map(v => createPackage(v))
	};
}

export function createPackageRestrictions(params: Partial<PackageRestrictionsFromServer>): PackageRestrictions {
	return {
		liveFeatures: params.live_features,
		adsFree: params.ads_free,
		feed: params.feed,
		networking: params.networking,
		files: params.files,
		accessRestrictions: params.access_restrictions,
		partners: params.partners,
		analytics: params.analytics,
		customMenu: params.custom_menu,
		tracks: params.tracks,
		integrations: params.integrations,
		hideWebsite: params.hide_website,
		hideReviews: params.hide_reviews,
		apiAccess: params.api_access,
		dataAccessInMonths: params.data_access_in_months,
		socialWall: params.social_wall,
		branding: params.branding,
		cname: params.cname,
		gamification: params.gamification
	} as PackageRestrictions
}


export function createPackageRestrictionsToServer(params: Partial<PackageRestrictions>): PackageRestrictionsFromServer {
	return {
		live_features: params.liveFeatures,
		ads_free: params.adsFree,
		feed: params.feed,
		networking: params.networking,
		files: params.files,
		access_restrictions: params.accessRestrictions,
		partners: params.partners,
		analytics: params.analytics,
		custom_menu: params.customMenu,
		tracks: params.tracks,
		integrations: params.integrations,
		hide_website: params.hideWebsite,
		hide_reviews: params.hideReviews,
		api_access: params.apiAccess,
		data_access_in_months: params.dataAccessInMonths,
		social_wall: params.socialWall,
		branding: params.branding,
		cname: params.cname,
		gamification: params.gamification
	} as PackageRestrictionsFromServer
}

export function printFormattedPrice(price: number, currency: string, fractionSize: number = 0) {
	const formattedPrice = new Intl.NumberFormat('en-US', {
		minimumFractionDigits: fractionSize,
		maximumFractionDigits: fractionSize
	}).format(+price);

	switch (currency) {
		case 'EUR':
			return formattedPrice + '€';
		case 'GBP':
			return '£' + formattedPrice;
		case 'CZK':
			return formattedPrice + ' Kč';
		case 'CNY':
			return '￥' + formattedPrice;
		case 'CHF':
			return 'CHF ' + formattedPrice;
		case 'AUD':
			return '$ ' + formattedPrice + ' AUD';
		case 'CAD':
			return '$ ' + formattedPrice + ' CAD';
		case 'NZD':
			return '$ ' + formattedPrice + ' NZD';
		default:
			return '$' + formattedPrice;
	}
}

export function printPackageString(type: PackageType) {
	switch (type) {
		case PackageType.FREE:
			return 'ADMIN.PAYMENT.PACKAGES.FREE';
		case PackageType.BUSINESS:
			return 'ADMIN.PAYMENT.PACKAGES.BUSINESS';
		case PackageType.ENTERPRISE:
			return 'ADMIN.PAYMENT.PACKAGES.ENTERPRISE';
		case PackageType.PREMIUM:
			return 'ADMIN.PAYMENT.PACKAGES.PREMIUM';
	}
}
