import {Injectable, Renderer2} from '@angular/core';
import {ID} from '@datorama/akita';
import {environment} from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class SmartTechnologiesService {

	constructor() {
	}

	saveEmail(id: ID, email: string, name: string, appsumo: boolean) {
		try {
			if (environment.production && !!smartsupp) {
				smartsupp('email', email);
				if (appsumo) {
					smartsupp('variables', {
						appsumo: 'true',
					});
				}
			}
		} catch (e) {
		}
	};

	changeChatClass(renderer2: Renderer2, clazz: string) {
		if (environment.production) {
			const chatApp = document.getElementById('chat-application');
			if (!chatApp) {
				setTimeout(() => this.changeChatClass(renderer2, clazz), 1000)
			} else {
				renderer2.removeAttribute(chatApp, 'class');
				renderer2.addClass(chatApp, clazz);
				// This is to fix the bug with smartsupp being 10px tall
				renderer2.setStyle(chatApp, 'width', '180px');
				renderer2.setStyle(chatApp, 'height', '70px');
			}
		}
	}

	toggleMobileStyle(renderer2: Renderer2, isMobile: boolean) {
		if (environment.production) {
			const chatApp = document.getElementById('chat-application');
			if (!chatApp) {
				setTimeout(() => this.toggleMobileStyle(renderer2, isMobile), 1000)
			} else {
				renderer2.removeStyle(chatApp, 'display');
				renderer2.setStyle(chatApp, 'display', isMobile ? 'none' : 'block');
			}
		}
	}

	setLanguage(language: string) {
		try {
			if (environment.production && smartsupp) {
				smartsupp('language', language);
			}
		} catch (e) {
		}
	}
}
