import moment, {Moment} from 'moment-timezone';
import {createEvent, Event, EventFromServer} from './event';
import {Image} from './image';
import {
	createPackageRestrictions,
	createPackageRestrictionsToServer,
	Membership,
	PackageRestrictions,
	PackageRestrictionsFromServer
} from './package';
import {dateTimeFromServer} from '../../shared/utils/moment';
import {createUser, User, UserFromServer} from './user';
import {PaymentOrigin, PaymentState} from './payment';
import {createInvoice, Invoice, InvoiceFromServer} from './invoice';
import {ID} from "@datorama/akita";

export enum AnalyticsLevel {
	BASIC = 0,
	ADVANCED = 1,
	PROFESSIONAL = 2,
}

export enum IntegrationLevel {
	NONE = 0,
	PREMIUM = 1,
	BUSINESS = 2,
}

export interface CompanyPackageRestrictionsFromServer extends PackageRestrictionsFromServer {
	number_of_events_per_year: number,
	number_of_used_events: number,
	number_of_attendees_per_event: number,
	number_of_members: number,
	number_of_invited_members: number,
	has_unlimited_events_per_year: boolean,
	has_unlimited_members: boolean,
	has_unlimited_attendees_per_event: boolean
}

export interface CompanyPackageRestrictions extends PackageRestrictions {
	numberOfEventsPerYear: number,
	numberOfUsedEvents: number,
	numberOfEventsLeft: number,
	numberOfAttendeesPerEvent: number,
	numberOfMembers: number,
	numberOfInvitedMembers: number,
	hasUnlimitedEventsPerYear: boolean,
	hasUnlimitedMembers: boolean,
	hasUnlimitedAttendeesPerEvent: boolean
}

export interface CompanyBrandingFromServer {
	active_color: string;
	active_contrast: string;
	background_color: string;
	background_contrast: string;
	photo?: Image;
	media_id: number;
}

export interface CompanyBranding {
	activeColor: string;
	activeContrast: string;
	backgroundColor: string;
	backgroundContrast: string;
	photo: Image;
}

export interface CompanyPaymentFromServer {
	id: ID;
	membership: Membership;
	attendees: number;
	created_at: string;
	active_until?: string;
	active: boolean;
	last_price: number;
	last_currency: string;
	last_invoice?: InvoiceFromServer;
	subscription_id: string;
	next_charge_price: string;
	fastspring_account_url: string;
	origin: string;
	state: string;
}

export interface CompanyPayment {
	id: ID;
	membership: Membership;
	createdAt: Moment;
	activeUntil?: Moment;
	active: boolean;
	lastPrice: number;
	lastInvoice?: Invoice;
	lastCurrency: string;
	nextChargePrice: string;
	fastspringAccountUrl: string;
	origin: PaymentOrigin;
	state: PaymentState;
}

export interface CompanyFromServer {
	id: number;
	name: string;
	code: string;
	created_at: string;
	updated_at: string;
	is_admin: boolean;
	photo: string;
	payment: CompanyPaymentFromServer;
	custom_branding: CompanyBrandingFromServer;
	media_id?: number;
	user_events?: EventFromServer[],
	events_left: number;
	admins: UserFromServer[];
}

export interface Company {
	id: number;
	name: string;
	code: string;
	createdAt: Moment;
	updatedAt: Moment;
	isAdmin: boolean;
	payment: CompanyPayment;
	branding?: CompanyBranding;
	photo: Image;
	userEvents: Event[];
	mediaId: number;
	userDefault: boolean,
	eventsLeft: number;
	admins: User[];
}

/**
 * A factory function that creates Companies
 */
export function createCompany(params: Partial<CompanyFromServer>) {
	return {
		id: params.id,
		name: params.name,
		code: params.code,
		createdAt: moment(params.created_at),
		updatedAt: moment(params.updated_at),
		isAdmin: params.is_admin,
		payment: createCompanyPayment(params.payment),
		branding: params.custom_branding ? createCompanyBranding(params.custom_branding) : null,
		photo: params.photo as Image,
		userEvents: params.user_events?.map(createEvent) ?? [],
		userDefault: false,
		eventsLeft: params.events_left,
		admins: params?.admins?.map(userFromServer => createUser(userFromServer)) ?? [],
	} as Company;
}

/**
 * A factory function that creates Companies
 */
export function createCompanyToServer(params: Partial<Company>) {
	return {
		id: params.id,
		name: params.name,
		code: params.code,
		media_id: params.mediaId
	} as CompanyFromServer;
}


export function createCompanyPayment(params: Partial<CompanyPaymentFromServer>): CompanyPayment {
	return {
		id: params.id,
		attendees: params.attendees,
		createdAt: dateTimeFromServer(params.created_at),
		membership: params.membership,
		activeUntil: dateTimeFromServer(params.active_until),
		active: params.active,
		lastPrice: params.last_price,
		lastCurrency: params.last_currency,
		lastInvoice: params.last_invoice ? createInvoice(params.last_invoice) : null,
        subscriptionId: params.subscription_id,
		nextChargePrice: params.next_charge_price,
		fastspringAccountUrl: params.fastspring_account_url,
		origin: params.origin,
		state: params.state
	} as CompanyPayment;
}

export function createCompanyBranding(params: Partial<CompanyBrandingFromServer>): CompanyBranding {
	return {
		activeColor: params.active_color,
		activeContrast: params.active_contrast,
		backgroundColor: params.background_color,
		backgroundContrast: params.background_contrast,
		photo: params.photo,
	} as CompanyBranding;
}

export function createCompanyBrandingToServer(params: Partial<CompanyBranding>): CompanyBrandingFromServer {
	return {
		active_color: params.activeColor,
		active_contrast: params.activeContrast,
		background_color: params.backgroundColor,
		background_contrast: params.backgroundContrast,
		photo: params.photo,
	} as CompanyBrandingFromServer;
}

export function createCompanyPackageRestrictions(params: Partial<CompanyPackageRestrictionsFromServer>): CompanyPackageRestrictions {
	return {
		numberOfEventsPerYear: params.number_of_events_per_year,
		numberOfUsedEvents: params.number_of_used_events,
		numberOfEventsLeft: params.number_of_events_per_year - (params.has_unlimited_events_per_year ? 0 : params.number_of_used_events),
		numberOfAttendeesPerEvent: params.number_of_attendees_per_event,
		numberOfMembers: params.number_of_members,
		numberOfInvitedMembers: params.number_of_invited_members,
		hasUnlimitedEventsPerYear: params.has_unlimited_events_per_year,
		hasUnlimitedMembers: params.has_unlimited_members,
		hasUnlimitedAttendeesPerEvent: params.has_unlimited_attendees_per_event,
		...createPackageRestrictions(params)
	} as CompanyPackageRestrictions
}


export function createCompanyPackageRestrictionsToServer(params: Partial<CompanyPackageRestrictions>): CompanyPackageRestrictionsFromServer {
	return {
		number_of_events_per_year: params.numberOfEventsPerYear,
		number_of_attendees_per_event: params.numberOfAttendeesPerEvent,
		number_of_members: params.numberOfMembers,
		...createPackageRestrictionsToServer(params)
	} as CompanyPackageRestrictionsFromServer
}
