import {ID} from '@datorama/akita';
import {PaymentState} from './payment';
import {Moment} from 'moment-timezone';
import {dateTimeFromServerInLocalTimezone} from '../../shared/utils/moment';

export interface InvoicePreviewFromServer {
	id: ID;
	product: string;
	number: string;
	price: number;
	currency: string;
	url: string;
	created_at: string;
	start_at: string;
	end_at: string;
	paid_at: string;
	state: string;
	is_refund: boolean;
}

export interface InvoicePreview {
	id: ID;
	product: string;
	number: string;
	price: number;
	currency: string;
	url: string;
	createdAt: Moment;
	startAt: Moment;
	endAt: Moment;
	isPaid: boolean;
	state: PaymentState;
	isRefund: boolean;
}

export function createInvoicePreview(params: Partial<InvoicePreviewFromServer>) {
	return {
		id: params.id,
		product: params.product,
		number: params.number,
		price: params.price,
		currency: params.currency,
		url: params.url,
		createdAt: dateTimeFromServerInLocalTimezone(params.created_at),
		startAt: dateTimeFromServerInLocalTimezone(params.start_at),
		endAt: dateTimeFromServerInLocalTimezone(params.end_at),
		isPaid: !!params.paid_at,
		state: params.state,
		isRefund: params.is_refund
	} as InvoicePreview
}
