import moment, {Moment} from 'moment-timezone';
import {AnalyticsLevel, Company, CompanyFromServer, createCompany, IntegrationLevel} from './company';
import {ID} from '@datorama/akita';
import {isAdmin, UserRoles} from './user';
import {createPackageRestrictions, createPackageRestrictionsToServer, Membership} from './package';
import {dateTimeFromServer, dateTimeFromServerInUTC} from '../../shared/utils/moment';
import {Image} from './image';
import {PaymentOrigin, PaymentState} from "./payment";

export enum EventType {
	'in-person',
	virtual,
	hybrid
}

export enum EventStatus {
	hidden,
	public = 3,
	archived = 4,
	cloning = 5
}

export enum ScheduleType {
	grid,
	list
}

export enum RatingLevel {
	on = 'on',
	hide = 'hide',
	off = 'off'
}

export enum EventFeature {
	API = 'api',
	NETWORKING = 'networking',
	NETWORKING_SHOW_ALL_ATTENDEES = 'networking_show_all_attendees',
	FEEDS = 'feeds',
	PIN = 'pin',
	WHITELIST = 'whitelist',
	AM_PM_TIME = 'am_pm_time',
	TRACKS = 'tracks',
	BRANDING = 'branding',
	HIDE_REVIEWS = 'hide/reviews',
	HIDE_MY_AGENDA = 'hide/my_agenda',
	RESTRICT_ANONYMOUS = 'restrict/anonymous',
	SOCIAL_WALL = 'social_wall',
	GAMIFICATION = 'gamification'
}

export interface CnameFromServer {
	id: ID;
	domain: string;
	is_dns_verified: boolean;
	is_ssl_active: boolean;
	is_active: boolean;
	outdated: boolean;
	event_id: ID;
}

export interface Cname {
	id: ID;
	domain: string;
	isDnsVerified: boolean;
	isSslActive: boolean;
	isActive: boolean;
	outdated: boolean;
}

export interface EventPaymentFromServer {
    id: ID;
	membership: Membership;
	created_at: string;
	origin: string;
	state: string;
	active_until: string
}

export interface EventPayment {
    id: ID;
	membership: Membership;
	createdAt: Moment;
	origin: PaymentOrigin;
	state: PaymentState;
	activeUntil: Moment
}

export interface EventPackageRestrictionsFromServer {
	additional_admins: number,
	additional_attendees: number,
	is_gratis: boolean,
	live_features: boolean,
	ads_free: boolean,
	feed: boolean,
	networking: boolean,
	files: boolean,
	access_restrictions: boolean,
	partners: boolean,
	analytics: AnalyticsLevel,
	custom_menu: boolean,
	tracks: boolean,
	integrations: IntegrationLevel,
	hide_website: boolean,
	hide_reviews: boolean,
	api_access: boolean,
	data_access_in_months: number,
	social_wall: boolean,
	branding: boolean,
	cname: boolean,
	has_unlimited_admins: boolean,
	number_of_invited_admins: number,
	number_of_admins: number,
	has_unlimited_attendees: boolean,
	number_of_invited_attendees: number,
	number_of_attendees: number,
	gamification: boolean
}

/**
 private businessOnlyFeatures = [
 Features.BRANDING,
 Features.INTEGRATIONS_BUSINESS,
 Features.SOCIAL_WALL
 ];*/

export interface EventPackageRestrictions {
	additionalAdmins: number,
	additionalAttendees: number,
	isGratis: boolean,
	liveFeatures: boolean,
	adsFree: boolean,
	feed: boolean,
	networking: boolean,
	files: boolean,
	accessRestrictions: boolean,
	partners: boolean,
	analytics: AnalyticsLevel,
	customMenu: boolean,
	tracks: boolean,
	integrations: IntegrationLevel,
	hideWebsite: boolean,
	hideReviews: boolean,
	apiAccess: boolean,
	dataAccessInMonths: number,
	socialWall: boolean,
	branding: boolean,
	cname: boolean,
	hasUnlimitedAdmins: boolean,
	numberOfInvitedAdmins: number,
	numberOfAdmins: number,
	hasUnlimitedAttendees: boolean,
	numberOfInvitedAttendees: number,
	numberOfAttendees: number,
	gamification: boolean
}

export interface EventAppBrandingFromServer {
	active_color: string;
	active_contrast: string;
	background_color: string;
	background_contrast: string;
	partners_color?: string;
	partners_contrast?: string;
	photo_offset: number;
	photo?: Image;
	media_id: number;
}

export interface EventAppBranding {
	activeColor: string;
	activeContrast: string;
	backgroundColor: string;
	backgroundContrast: string;
	partnersColor: string;
	partnersContrast: string;
	photoOffset: number;
	photo: Image;
}

export interface EventWebBrandingFromServer {
	active_color: string;
	active_contrast: string;
	background_color: string;
	background_contrast: string;
	partners_color?: string;
	partners_contrast?: string;
	photo?: Image;
	media_id: number;
	photo_overlay: boolean;
}

export interface EventWebBranding {
	activeColor: string;
	activeContrast: string;
	backgroundColor: string;
	backgroundContrast: string;
	partnersColor: string;
	partnersContrast: string;
	photo: Image;
	photoOverlay: boolean;
}

export interface EventMailBrandingFromServer {
	id?: number;
	active_color: string;
	active_contrast: string;
	attendee_invite_subject?: string;
	attendee_invite_title?: string;
	attendee_invite_body?: string;
	attendee_invite_button?: string;
	attendee_reminder_subject?: string;
	attendee_reminder_title?: string;
	attendee_reminder_body?: string;
	attendee_reminder_button?: string;
	photo?: Image;
	media_id: number;
	hide_logo: boolean;
}

export interface EventMailBranding {
	id?: ID;
	activeColor: string;
	activeContrast: string;
	attendeeInviteSubject?: string;
	attendeeInviteTitle?: string;
	attendeeInviteBody?: string;
	attendeeInviteButton?: string;
	attendeeReminderSubject?: string;
	attendeeReminderTitle?: string;
	attendeeReminderBody?: string;
	attendeeReminderButton?: string;
	photo: Image;
	hideLogo: boolean;
}

export interface EventCloneAttributes {
	schedule: boolean,
	speakers: boolean,
	tracks: boolean,
	partners: boolean,
	abouts: boolean,
	branding: boolean,
	website: boolean,
	admins: boolean,
	moderators: boolean,
	attendees: boolean
}

export interface EventOwner {
	name: string;
	email: string;
}

export interface EventPreviewFromServer {
	secret: string;
	valid: boolean;
	valid_until: string;
}

export interface EventPreview {
	secret: string;
	valid: boolean;
	validUntil: Moment;
}

export interface EventContactInformation {
	email:string;
	name: string;
	address: string;
	city: string;
	country: string;
	phone: string;
}

export interface EventFromServer {
	id: number;
	name: string;
	annotation: string;
	latitude: number;
	longitude: number;
	timezone: string;
	start: string;
	end: string;
	icon: string;
	photo?: any;
	category: string;
	feeds: boolean;
	networking: boolean;
	networking_show_all_attendees: boolean;
	testing_code: number;
	status: number;
	number_of_visitors: number;
	created_at: string;
	updated_at: string;
	address: string;
	am_pm_time: boolean;
	tw_account: string;
	tw_hashtags: string[];
	ins_hashtags: string[];
	reviewed_at: string;
	rejected_at: string;
	pin_code: string;
	locked: boolean;
	beacon_uuid: string;
	qr_code: string;
	real_attendees: number;
	rejection_reason: string;
	country_code: string;
	email_language: string;
	background_color: string;
	social: boolean;
	whitelist: boolean;
	agenda_scale: number;
	agenda_midnight_diff: number;
	restrict_anonymous: boolean;
	hide_reviews: boolean;
	rating_level: RatingLevel;
	hide_my_agenda: boolean;
	default_schedule_view: number;
	public_event_page: boolean;
	status_name: string;
	owner?: EventOwner;
	hall_count: number;
	links?: any;
	twitter: boolean;
	instagram: boolean;
	payment: EventPaymentFromServer;
	company?: CompanyFromServer;
	company_id: ID,
	public_api_token?: any;
	all_feeds_count: number;
	qr_code_url: string;
	ticketing: boolean;
	buy_ticket_url?: any;
	role: string;
	slug: string;
	public_event_page_url: string;
	type: number;
	custom_branding?: EventAppBrandingFromServer;
	custom_web_branding?: EventWebBrandingFromServer;
	custom_mail_branding?: EventMailBrandingFromServer;
	has_tracks: boolean;
	cname: CnameFromServer;
	preview: EventPreviewFromServer;
	social_wall: boolean;
	social_wall_auto_approve: boolean;
	contact_information: EventContactInformation;
	send_attendee_invitations: boolean;
	has_gamification: boolean;
}

export interface Event {
	id: ID;
	name: string;
	annotation: string;
	latitude: number;
	longitude: number;
	timezone: string;
	isInDifferentTimezone: boolean;
	startInUTC: Moment;
	start: Moment;
	end: Moment;
	icon: string;
	photo: string;
	category: string;
	feeds: boolean;
	networking: boolean;
	networkingShowAllAttendees: boolean;
	testingCode: number;
	status: number;
	numberOfVisitors: number;
	createdAt: Moment;
	updatedAt: Moment;
	address: string;
	amPmTime: boolean;
	twAccount: string;
	twHashtags: Set<string>;
	insHashtags: Set<string>;
	reviewedAt: Moment;
	rejectedAt: Moment;
	pinCode: string;
	locked: boolean;
	beaconUuid: string;
	qrCode: string;
	realAttendees: number;
	rejectionReason: string;
	countryCode: string;
	emailLanguage: string;
	backgroundColor: string;
	social: boolean;
	whitelist: boolean;
	agendaScale: number;
	agendaMidnightDiff: number;
	statusName: string;
	owner: EventOwner;
	hallCount: number;
	links?: [];
	twitter: boolean;
	instagram: boolean;
	payment: EventPayment;
	company?: Company;
	isCompanyAdmin: boolean;
	publicApiToken: string;
	allFeedsCount: number;
	qrCodeUrl: string;
	ticketing: boolean;
	buyTicketUrl?: string;
	role?: UserRoles;
	publicEventPageUrl: string;
	publicEventPage: boolean;
	restrictAnonymous: boolean;
	slug: string;
	hideReviews: boolean;
	ratingLevel: RatingLevel;
	hideMyAgenda: boolean;
	defaultScheduleView: ScheduleType;
	companyId: ID;
	type: EventType;
	supportStreams: boolean;
	branding?: EventAppBranding;
	brandingWeb?: EventWebBranding;
	brandingMail?: EventMailBranding;
	tracks: boolean;
	cname: Cname;
	preview: EventPreview;
	socialWall: boolean;
	socialWallAutoApprove: boolean;
	contactInformation: EventContactInformation;
	sendAttendeeInvitations: boolean;
	hasGamification: boolean;
}

/**
 * A factory function that creates Events
 */
export function createEvent(params: Partial<EventFromServer>): Event {
	const localTimeFormatted = moment().local().startOf('m').format('YYYY-MM-DD HH:mm:ss');
	const eventTimeFormatted = moment.tz(moment(), params.timezone).startOf('m').format('YYYY-MM-DD HH:mm:ss');
	return {
		id: params.id,
		name: params.name,
		annotation: params.annotation,
		latitude: params.latitude,
		longitude: params.longitude,
		timezone: params.timezone,
		isInDifferentTimezone: localTimeFormatted !== eventTimeFormatted,
		startInUTC: dateTimeFromServerInUTC(params.start),
		start: moment.tz(params.start, params.timezone),
		end: moment.tz(params.end, params.timezone),
		icon: params.icon,
		photo: params.photo,
		category: params.category,
		feeds: params.feeds,
		networking: params.networking,
		networkingShowAllAttendees: params.networking_show_all_attendees,
		testingCode: params.testing_code,
		status: params.status,
		numberOfVisitors: params.number_of_visitors,
		createdAt: moment.utc(params.created_at).tz(params.timezone),
		updatedAt: moment.utc(params.updated_at).tz(params.timezone),
		address: params.address,
		amPmTime: params.am_pm_time,
		twAccount: params.tw_account,
		twHashtags: new Set(params.tw_hashtags),
		insHashtags: new Set(params.ins_hashtags),
		reviewedAt: dateTimeFromServerInUTC(params.reviewed_at),
		rejectedAt: dateTimeFromServerInUTC(params.rejected_at),
		pinCode: params.pin_code,
		locked: params.locked,
		beaconUuid: params.beacon_uuid,
		qrCode: params.qr_code,
		realAttendees: params.real_attendees,
		rejectionReason: params.rejection_reason,
		countryCode: params.country_code,
		emailLanguage: params.email_language,
		backgroundColor: params.background_color,
		social: params.social,
		whitelist: params.whitelist,
		agendaScale: params.agenda_scale,
		agendaMidnightDiff: params.agenda_midnight_diff,
		statusName: params.status_name,
		owner: params.owner,
		hallCount: params.hall_count,
		links: params.links,
		twitter: params.twitter,
		instagram: params.instagram,
		payment: createEventPayment(params.payment),
		companyId: params.company_id,
		company: params.company_id ? createCompany(params.company) : null,
		isCompanyAdmin: params.company_id ? params.company.is_admin : isAdmin(params.role as UserRoles),
		publicApiToken: params.public_api_token,
		allFeedsCount: params.all_feeds_count,
		qrCodeUrl: params.qr_code_url,
		ticketing: params.ticketing,
		buyTicketUrl: params.buy_ticket_url,
		role: params.role,
		publicEventPageUrl: params.public_event_page_url,
		publicEventPage: params.public_event_page,
		restrictAnonymous: params.restrict_anonymous,
		hideReviews: params.hide_reviews,
		ratingLevel: params.rating_level,
		hideMyAgenda: params.hide_my_agenda,
		defaultScheduleView: params.default_schedule_view,
		slug: params.slug,
		type: params.type,
		supportStreams: params.type === EventType.virtual || params.type === EventType.hybrid,
		branding: params.custom_branding && createEventAppBranding(params.custom_branding),
		brandingWeb: params.custom_web_branding && createEventWebBranding(params.custom_web_branding),
		brandingMail: params.custom_mail_branding && createEventMailBranding(params.custom_mail_branding),
		tracks: params.has_tracks,
		cname: params.cname ? createCnameFromServer(params.cname) : null,
		preview: params.preview ? createEventPreviewFromServer(params.preview) : null,
		socialWall: params.social_wall,
		socialWallAutoApprove: params.social_wall_auto_approve,
		contactInformation: params.contact_information,
		sendAttendeeInvitations: params.send_attendee_invitations,
		hasGamification: params.has_gamification
	} as Event;
}

/**
 * A factory function that creates Events
 */
export function createEventToServer(params: Partial<Event>) {
	return {
		id: params.id,
		name: params.name,
		annotation: params.annotation,
		latitude: params.latitude,
		longitude: params.longitude,
		timezone: params.timezone,
		start: params.start.format('YYYY-MM-DD'),
		end: params.end.format('YYYY-MM-DD'),
		icon: params.icon,
		photo: params.photo,
		category: params.category,
		feeds: params.feeds,
		networking: params.networking,
		networking_show_all_attendees: params.networkingShowAllAttendees,
		testing_code: params.testingCode,
		status: params.status,
		number_of_visitors: params.numberOfVisitors,
		address: params.address,
		tw_account: params.twAccount,
		tw_hashtags: params.twHashtags ? [...params.twHashtags.values()] : [],
		ins_hashtags: params.insHashtags ? [...params.insHashtags.values()] : [],
		pin_code: params.pinCode,
		locked: params.locked,
		beacon_uuid: params.beaconUuid,
		qr_code: params.qrCode,
		real_attendees: params.realAttendees,
		rejection_reason: params.rejectionReason,
		country_code: params.countryCode,
		email_language: params.emailLanguage,
		background_color: params.backgroundColor,
		social: params.social,
		whitelist: params.whitelist,
		agenda_scale: params.agendaScale,
		agenda_midnight_diff: params.agendaMidnightDiff,
		status_name: params.statusName,
		owner: params.owner,
		hall_count: params.hallCount,
		links: params.links,
		twitter: params.twitter,
		instagram: params.instagram,
		company_id: params.companyId || params.company?.id,
		public_api_token: params.publicApiToken,
		all_feeds_count: params.allFeedsCount,
		qr_code_url: params.qrCodeUrl,
		ticketing: params.ticketing,
		buy_ticket_url: params.buyTicketUrl,
		role: params.role,
		restrict_anonymous: params.restrictAnonymous,
		public_event_page: params.publicEventPage,
		hide_reviews: params.hideReviews,
		rating_level: params.ratingLevel,
		hide_my_agenda: params.hideMyAgenda,
		type: params.type,
		social_wall: params.socialWall,
		social_wall_auto_approve: params.socialWallAutoApprove,
		contact_information: params.contactInformation,
		send_attendee_invitations: params.sendAttendeeInvitations,
		has_gamification: params.hasGamification
	} as EventFromServer;
}

export function createEventPayment(params: Partial<EventPaymentFromServer>): EventPayment {
	return {
        id: params.id,
		createdAt: dateTimeFromServer(params.created_at),
		membership: params.membership,
		origin: params.origin,
		state: params.state,
		activeUntil: dateTimeFromServer(params.active_until),
	} as EventPayment;
}

export function createEventPackageRestrictions(params: Partial<EventPackageRestrictionsFromServer>): EventPackageRestrictions {
	return {
		additionalAdmins: params.additional_admins,
		additionalAttendees: params.additional_attendees,
		isGratis: params.is_gratis,
		hasUnlimitedAdmins: params.has_unlimited_admins,
		numberOfInvitedAdmins: params.number_of_invited_admins,
		numberOfAdmins: params.number_of_admins,
		hasUnlimitedAttendees: params.has_unlimited_attendees,
		numberOfInvitedAttendees: params.number_of_invited_attendees,
		numberOfAttendees: params.number_of_attendees,
		...createPackageRestrictions(params)
	} as EventPackageRestrictions;
}

export function createEventPackageRestrictionsToServer(params: Partial<EventPackageRestrictions>): EventPackageRestrictionsFromServer {
	return {
		additional_admins: params.additionalAdmins,
		additional_attendees: params.additionalAttendees,
		is_gratis: params.isGratis,
		...createPackageRestrictionsToServer(params)
	} as EventPackageRestrictionsFromServer;
}

export function createEventAppBranding(params: Partial<EventAppBrandingFromServer>): EventAppBranding {
	return {
		activeColor: params.active_color,
		activeContrast: params.active_contrast,
		backgroundColor: params.background_color,
		backgroundContrast: params.background_contrast,
		partnersColor: params.partners_color,
		partnersContrast: params.partners_contrast,
		photoOffset: params.photo_offset,
		photo: params.photo,
	} as EventAppBranding;
}

export function createEventWebBranding(params: Partial<EventWebBrandingFromServer>): EventWebBranding {
	return {
		activeColor: params.active_color,
		activeContrast: params.active_contrast,
		backgroundColor: params.background_color,
		backgroundContrast: params.background_contrast,
		partnersColor: params.partners_color,
		partnersContrast: params.partners_contrast,
		photo: params.photo,
		photoOverlay: params.photo_overlay
	} as EventWebBranding;
}

export function createEventMailBranding(params: Partial<EventMailBrandingFromServer>): EventMailBranding {
	return {
		id: params.id,
		activeColor: params.active_color,
		activeContrast: params.active_contrast,
		attendeeInviteSubject: params.attendee_invite_subject,
		attendeeInviteTitle: params.attendee_invite_title,
		attendeeInviteBody: params.attendee_invite_body,
		attendeeInviteButton: params.attendee_invite_button,
		attendeeReminderSubject: params.attendee_reminder_subject,
		attendeeReminderTitle: params.attendee_reminder_title,
		attendeeReminderBody: params.attendee_reminder_body,
		attendeeReminderButton: params.attendee_reminder_button,
		photo: params.photo,
		hideLogo: params.hide_logo
	} as EventMailBranding;
}

export function createCnameFromServer(params: Partial<CnameFromServer>): Cname {
	return {
		id: params.id,
		domain: params.domain,
		isDnsVerified: params.is_dns_verified,
		isSslActive: params.is_ssl_active,
		isActive: params.is_active,
		outdated: params.outdated
	} as Cname;
}

export function createEventPreviewFromServer(params: Partial<EventPreviewFromServer>): EventPreview {
	return {
		secret: params.secret,
		valid: params.valid,
		validUntil: dateTimeFromServer(params.valid_until)
	} as EventPreview;
}

export function getEventStatusClass(event: Event) {
	switch (event.status) {
		case EventStatus.hidden:
			return 'bg-white text-dark-gray';
		case EventStatus.public:
			return 'bg-success text-white';
		case EventStatus.cloning:
			return 'bg-warning text-white';
	}
}

export function getEventStatusText(event: Event) {
	switch (event.status) {
		case EventStatus.hidden:
			return 'GENERAL.STATUS.DRAFT';
		case EventStatus.public:
			return 'GENERAL.STATUS.PUBLIC';
		case EventStatus.cloning:
			return 'GENERAL.STATUS.CLONING';
	}
}

export const eventCategories = [
	'BUSINESS',
	'MARKETING',
	'TECHNOLOGY',
	'MEDICAL',
	'EDUCATION',
	'OTHERS'
];
