import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {OAuthConfig, OAuthService} from '../o-auth/o-auth.service';
import {switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AppleIdAuthService} from '../apple-id-auth/apple-id-auth.service';

@Injectable({
	providedIn: 'root'
})
export class SocialService {
	private facebookConfig: OAuthConfig;
	private facebookConfigForInstagram: OAuthConfig;
	private linkedInConfig: OAuthConfig;
	private eventbriteConfig: OAuthConfig;
	private mailChimpConfig: OAuthConfig;
	private zoomConfig: OAuthConfig;

	private suffixV1 = '/v1';

	static redirectUri = location.origin + '/o-auth-redirect/';

	constructor(
		public oAuthService: OAuthService,
		private appleIdAuthService: AppleIdAuthService,
		private http: HttpClient
	) {
		this.facebookConfig = {
			clientId: environment.socials.facebookId,
			authorizationEndpoint: 'https://www.facebook.com/v20.0/dialog/oauth',
			redirectUri: SocialService.redirectUri,
			scope: ['public_profile', 'email', 'user_friends'],
			width: 520,
			height: 610
		};
		this.facebookConfigForInstagram = {
			clientId: environment.socials.facebookId,
			authorizationEndpoint: 'https://www.facebook.com/v20.0/dialog/oauth',
			redirectUri: SocialService.redirectUri,
			scope: ['instagram_basic', 'instagram_manage_insights', 'pages_show_list'],
			width: 520,
			height: 610
		};
		this.linkedInConfig = {
			clientId: environment.socials.linkedInId,
			authorizationEndpoint: 'https://www.linkedin.com/oauth/v2/authorization',
			redirectUri: SocialService.redirectUri,
			scope: ['r_liteprofile', 'r_emailaddress'],
			width: 527,
			height: 581
		};
		this.eventbriteConfig = {
			clientId: environment.socials.eventbriteId,
			authorizationEndpoint: 'https://www.eventbrite.com/oauth/authorize',
			redirectUri: SocialService.redirectUri,
			width: 900,
			height: 480
		};
		this.mailChimpConfig = {
			clientId: environment.socials.mailChimpId,
			authorizationEndpoint: 'https://login.mailchimp.com/oauth2/authorize',
			redirectUri: SocialService.redirectUri,
			width: 580,
			height: 780
		};
		this.zoomConfig = {
			clientId: environment.socials.zoomId,
			authorizationEndpoint: 'https://zoom.us/oauth/authorize',
			redirectUri: SocialService.redirectUri,
			width: 710,
			height: 850
		};
	}

	facebook() {
		return this.oAuthService.authorize(this.facebookConfig).pipe(
			switchMap((code) => {
				return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/login/facebook`, {
					code,
					redirect_uri: this.facebookConfig.redirectUri
				});
			})
		);
	}

	linkInstagram() {
		return this.oAuthService.authorize(this.facebookConfigForInstagram);
	}

	linkedIn() {
		return this.oAuthService.authorize(this.linkedInConfig).pipe(
			switchMap((code) => {
				return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/login/linked_in`, {
					code,
					redirect_uri: this.linkedInConfig.redirectUri
				});
			})
		);
	}

	linkEventbrite() {
		return this.oAuthService.authorize(this.eventbriteConfig);
	}

	linkZoom() {
		return this.oAuthService.authorize(this.zoomConfig);
	}

	apple() {
		return this.appleIdAuthService.signIn(environment.socials.appleId, SocialService.redirectUri).pipe(
			switchMap((response) => {
				const params: any = {
					code: response.authorization.code,
					client_id: environment.socials.appleId,
					redirect_uri: SocialService.redirectUri
				};
				if (response?.user?.name) {
					params.first_name = response.user.name.firstName;
					params.last_name = response.user.name.lastName;
				}
				return this.http.post(`${environment.appApi.baseUrl}${this.suffixV1}/login/apple`, params);
			})
		);
	}

	linkMailChimp() {
		return this.oAuthService.authorize(this.mailChimpConfig);
	}
}
