import {Injectable} from '@angular/core';
import {ActiveState, EntityState, EntityStore, ID, StoreConfig} from '@datorama/akita';
import {Event, EventPackageRestrictions, EventStatus} from '../../core/models/event';
import moment, {Moment} from "moment-timezone";

export interface EventsFilter {
	query?: string;
	status?: EventStatus;
}

export interface EventsState extends EntityState<Event>, ActiveState {
    ui: {
        selectedPackage?: ID;
        searchFilter?: EventsFilter
    },
    restrictions?: EventPackageRestrictions;
    restrictionsCacheValidUntil?: Moment;
}

const initialState = {
    ui: {
        selectedPackage: null,
        searchFilter: {
            query: null,
            status: null,
        }
    },
    restrictions: null,
    restrictionsCacheValidUntil: null
};

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'events', resettable: true})
export class EventsStore extends EntityStore<EventsState> {

	constructor() {
		super(initialState);
    }

    updateUI(ui: EventsState['ui']) {
        this.update(state => ({
            ui: {
                ...state.ui,
                ...ui
            }
        }));
    }

    getRestrictionsFromCache() {
        const state = this.getValue();
        if (moment().isBefore(state.restrictionsCacheValidUntil)) {
            return state.restrictions;
        }
        return null;
    }

    updateRestrictions(restrictions: EventsState['restrictions']) {
        this.update(state => ({
            restrictions: {
                ...state.restrictions,
                ...restrictions
            },
            restrictionsCacheValidUntil: moment().add(10, 'minutes')
        }));
    }

    clearRestrictionCache() {
        this.update(state => ({
            restrictionsCacheValidUntil: null
        }));
    }

}

