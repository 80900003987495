import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ID} from "@datorama/akita";

export interface DataLayerObject {
	eventCategory: string;
	eventAction: string;
	eventLabel: string;
	event: string;
	eventValue?: string;
}

export interface UserDetails {
	userId?: ID
	firstName?: string;
	lastName?: string;
	email?: string;
	subscribed?: boolean
	isCustomer?: boolean
}
export interface UserDataLayerObject extends UserDetails, DataLayerObject {}

@Injectable({
	providedIn: 'root'
})
export class GoogleTagService {

	private static sendTag(category: string, action: string, label: string, value?: string) {
		if (environment.production) {
			const dataLayerObject: DataLayerObject = {
				eventCategory: category,
				eventAction: action,
				eventLabel: label,
				event: 'EventeeEvent'
			};
			if (!!value) {
				dataLayerObject.eventValue = value;
			}
			try {
				if (dataLayer) {
					dataLayer?.push(dataLayerObject);
				}
			} catch (e) {
			}
		}
	}

	private static sendUserData(userDetails?: UserDetails) {
		if (environment.production) {
			const userDataLayerObject: UserDataLayerObject = {
				eventCategory: 'Identify',
				eventAction: 'Identification',
				eventLabel: 'identification successful',
				event: 'EventeeEvent',
				userId: userDetails.userId,
				firstName: userDetails.firstName,
				lastName: userDetails.lastName,
				email: userDetails.email,
				subscribed: userDetails.subscribed,
			};
			try {
				if (dataLayer) {
					dataLayer?.push(userDataLayerObject);
				}
			} catch (e) {
			}
		}
	}

	private static sendCustomerStatus(isCustomer: boolean) {
		if (environment.production) {
			const userDataLayerObject: UserDataLayerObject = {
				eventCategory: 'CustomerStatus',
				eventAction: 'Identification',
				eventLabel: 'user customer status updated successfully',
				event: 'EventeeEvent',
				isCustomer: isCustomer
			};
			try {
				if (dataLayer) {
					dataLayer?.push(userDataLayerObject);
				}
			} catch (e) {
			}
		}
	}

	public successfulLogin() {
		GoogleTagService.sendTag('Login', 'Success', 'login successful');
	}

	public successfulSignUp() {
		GoogleTagService.sendTag('SignUp', 'Success', 'sign up successful');
	}
	public identifyUser(userDetails?: UserDetails) {
		GoogleTagService.sendUserData(userDetails);
	}

	public updateUserCustomerStatus(isCustomer: boolean) {
		GoogleTagService.sendCustomerStatus(isCustomer);
	}

	public successfulSocialAuthorization(network: string, isLogin: boolean) {
		if (isLogin) {
			GoogleTagService.sendTag('Login', 'Success', network + ' login successful');
		} else {
			GoogleTagService.sendTag('SignUp', 'Success', network + ' sign up successful');
		}
	}
	public premiumBought(price: number) {
		GoogleTagService.sendTag('Premium', 'Payment', 'premium payment successful', price + '');
	}

	public businessBought(price: number) {
		GoogleTagService.sendTag('Business', 'Payment', 'business payment successful', price + '');
	}

	public enterpriseBought(price: number) {
		GoogleTagService.sendTag('Enterprise', 'Payment', 'enterprise payment successful', price + '');
	}

	public premiumLifetimeBought(price: number) {
		GoogleTagService.sendTag('PremiumLifetime', 'Payment', 'premium lifetime payment successful', price + '');
	}
}